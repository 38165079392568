import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import levenshtein from 'js-levenshtein';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import { Box, ButtonBase, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { VisibilityOutlined } from '@material-ui/icons';
import { Field, Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { Button, DiscardButton, SaveButton, useFeatures } from '@castiron/components';
import {
  BaseProduct,
  ChecklistValues,
  CustomProduct,
  FulfillmentType,
  Product,
  ProductFulfillment,
  ProductType,
  ProductPageContext,
  Customer,
  holidays,
  occasions,
  specialToTag,
  Special,
  addressSchema,
  TicketedEvent,
} from '@castiron/domain';
import {
  defaultTimeZone,
  getProductStatus,
  hasSchedule,
  isset,
  removeEmpty,
  stripHtml,
  useTracking,
} from '@castiron/utils';
import EditProductForm from './EditProductForm';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  createProductAction,
  createTemplateProductAction,
  updateProductAction,
} from '../../../store/reducers/products';
import { getShopAction, updateChecklistAction } from '../../../store/reducers/shops';
import Spinner from '../../Spinner';
import { openModal } from '../../../store/reducers/modalConductor';
import currency from 'currency.js';
import { productRepository, productTemplateRepository, shopRepository, specialRepository } from '../../../domain';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import ProductActionsDropdown from './FormComponents/ProductActionsDropdown';
import { useLocation } from 'react-router-dom';
import Variations from './FormComponents/Variations';
import SEO from './FormComponents/SEO';
import HeaderTabs from '../../Layout/Header/HeaderTabs';
import ProductErrorBox from './ProductErrorBox';
import { createCustomerAction } from '../../../store/reducers/customers';
import createTestQuote from '../../../lib/createTestQuote';
import { getService } from '../../../firebase';
import moment from 'moment-timezone';

const generateDescriptionService = getService('products', 'generatedescription');

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  errorBox: {
    marginBottom: 8,
    '& div': {
      /* This removes extra spacing on the icon that was preventing centering */
      lineHeight: '0px',
    },
  },
}));

interface Params {
  id?: string;
  type?: ProductType;
  productTemplateId?: string;
}

const numOrZero = (num?: number): number => {
  return num || 0;
};

const EditProduct: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{
    fromCreate: boolean;
    fromChecklist: boolean;
    generationId: string;
    generatedDescription: string;
  }>();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const { id, type: urlType, productTemplateId } = useParams<Params>();
  const { fromChecklist, fromCreate: fromCreate, generationId, generatedDescription } = location?.state || {};
  const context = location?.pathname.split('/')[1] as ProductPageContext;
  const features = useFeatures();

  const formikRef = useRef<FormikProps<any>>();

  const [type, setType] = useState<ProductType>(urlType);
  const [product, setProduct] = useState<BaseProduct>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isGettingProductTemplate, setIsGettingProductTemplate] = useState<boolean>(false);
  const [placeholders, setPlaceholders] = useState(null);
  const [isModalPopped, setIsModalPopped] = useState<boolean>();
  const [savedNewCreate, setSavedNewCreate] = useState(!fromCreate);
  /* for tracking purposes */
  const [productTemplateTitle, setProductTemplateTitle] = useState<string>(null);
  const [overrideTabValue, setOverrideTabValue] = useState<string>('');

  const { shop, products, fulfillments, userState } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
    fulfillments: state.shops.fulfillments,
    userState: state.shops.userState,
  }));

  const emptyFulfillment: ProductFulfillment = {
    pickup: false,
    delivery: false,
    shipping: false,
  };

  const fulfillmentTypes: FulfillmentType[] = ['pickup', 'delivery', 'shipping'];
  const activeFulfillments = fulfillments.filter(f => f.status === 'active');

  if (activeFulfillments.length == 0) {
    emptyFulfillment['pickup'] = true;
  } else {
    fulfillmentTypes.map(fulfillmentType => {
      const fulfillment = activeFulfillments?.filter(o => o.type == fulfillmentType);
      emptyFulfillment[fulfillmentType] = fulfillment.length == 0 ? false : true;
    });
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditMode = isset(id);
  const nonOnboardingProducts = products.filter(product => product.source !== 'onboarding');

  const holidaysTags = holidays.map(holiday => ({
    display: holiday,
    value: specialToTag(holiday),
  }));
  const occasionsTags = occasions.map(occasion => ({
    display: occasion,
    value: specialToTag(occasion),
  }));

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton
        isSubmitting={isSubmitting}
        onClick={async () => {
          if (fromCreate) {
            trackEvent('Product Abandoned', {
              productId: product.id,
            });
            await productRepository.delete(product.id);
          }
          history.push(fromChecklist ? '/' : `/${context || 'products'}`);
        }}
      />,
      <SaveButton
        handleSubmit={() => {
          window.scrollTo(0, 0);
          formikRef?.current?.handleSubmit();
        }}
        isSubmitting={isSubmitting}
      />,
    ]);
  }, [isSubmitting, formikRef?.current?.values, isMobile]);

  useEffect(() => {
    const getProduct = async id => {
      const product = await productRepository.get(id);
      setProduct({ ...product, type: product?.type || 'standard' });
      setType(product?.type || 'standard');
    };

    if (id) {
      getProduct(id);
    }

    setPageTitle(`Edit ${findContextString(true)}`);
    setBackLocation(productTemplateId ? `/${context}?tab=Templates` : true);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    const getProductTemplate = async () => {
      setIsGettingProductTemplate(true);
      const result = await productTemplateRepository.get(productTemplateId);
      if (result?.template) {
        const product = result?.template;
        const generateDescriptionResponse = await generateDescriptionService({
          title: product?.title,
          category: product?.category?.name,
          allergens: product?.allergen?.join(', '),
          dietary: product?.dietary?.join(', '),
        });
        setProduct(
          _.omit(
            {
              ...product,
              type: product?.type || 'standard',
              unlimitedInventory: product?.type === 'standard' || product?.type === 'event',
              description: generateDescriptionResponse?.description || product?.description,
            },
            'category',
            'imageObj',
            'inventory',
            'minimum',
            'price',
            'startingPrice',
          ),
        );
        setType(product?.type || 'standard');
        setProductTemplateTitle(result.displayName);
      }
      setPlaceholders(result?.placeholders || null);
      setIsGettingProductTemplate(false);
    };

    if (productTemplateId) {
      getProductTemplate();
    }
  }, [productTemplateId]);

  useEffect(() => {
    if (fromChecklist) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fromChecklist]);

  const findContextString = (isCapitalized?: boolean) => {
    if (isCapitalized) {
      return context === 'order-forms' ? 'Order Form' : context === 'products' ? 'Product' : 'Event';
    } else {
      return context === 'order-forms' ? 'order form' : context === 'products' ? 'product' : 'event'
    }
  };

  const handleUpdateSubpage = async (type, eventPagesToActivate?: string[], activeShopEvents?: Special[]) => {
    let dataUpdated = false;
    let newShopSubpageData = {
      ...shop?.shopSubpageData,
    };
    if (type === 'standard' && !shop?.shopSubpageData?.isShopPageEnabled) {
      dataUpdated = true;
      newShopSubpageData.isShopPageEnabled = true;
    } else if (type === 'custom' && !shop?.shopSubpageData?.quotes?.enabled) {
      dataUpdated = true;
      newShopSubpageData.quotes = {
        enabled: true,
        headline: shop?.shopSubpageData?.quotes?.headline || 'Custom Orders',
        description:
          shop?.shopSubpageData?.quotes?.description ||
          'Please select an item below to view options, availability, and to begin a quote.',
      };
    } else if (type === 'event' && !shop?.shopSubpageData?.ticketedEvents?.enabled) {
      dataUpdated = true;
      newShopSubpageData.ticketedEvents = {
        enabled: true,
        headline: shop?.shopSubpageData?.ticketedEvents?.headline || 'Events',
        description:
        shop?.shopSubpageData?.ticketedEvents?.description || '',
      };
    }

    let eventPagesUpdated = false;
    let newEvents = shop?.shopSubpageData?.events ? _.cloneDeep(shop?.shopSubpageData?.events) : [];
    eventPagesToActivate.forEach(eventTag => {
      const currentEventPage = newEvents?.find(event => event.tag === eventTag);
      if (!currentEventPage?.enabled || !currentEventPage?.showPopup) {
        eventPagesUpdated = true;
        dataUpdated = true;
        const content = activeShopEvents?.find(e => e.tag === eventTag)?.shopContent?.page;
        const newEventPage = {
          tag: eventTag,
          enabled: true,
          headline: currentEventPage?.headline || content?.bannerHeadline,
          description: currentEventPage?.description || content?.bannerDescription,
          showPopup: true,
        };
        if (currentEventPage) {
          newEvents = newEvents?.map(event => (event?.tag === eventTag ? newEventPage : event));
        } else {
          newEvents.push(newEventPage);
        }
      }
    });
    if (eventPagesUpdated) {
      newShopSubpageData.events = newEvents;
    }

    if (dataUpdated) {
      await shopRepository.updateProps(shop.id, {
        shopSubpageData: newShopSubpageData,
      });
      await dispatch(getShopAction(shop.id));
    }
  };

  const handleUpdateOrCreateSuccess = async (product, isEditMode) => {
    const status = getProductStatus(product, shop?.config?.timeZone);
    if (status === 'active' || status === 'scheduled') {
      const activeShopEvents = await specialRepository.findActiveShopEvents(shop?.config?.timeZone);
      const activeShopEventTags = activeShopEvents?.map(e => e.tag) || [];
      const eventPagesToActivate = _.intersection((product?.eventTags || []),  activeShopEventTags);
      await handleUpdateSubpage(product.type, eventPagesToActivate, activeShopEvents);
    }

    /* length === 1 because redux was updated with shop subpage data */
    const isFirstProduct = nonOnboardingProducts.length === 1;
    if (fromCreate) {
      if (isFirstProduct) {
        await handleFirstProductCreated(product);
      }
      setSavedNewCreate(true);
    }

    const shouldSendTestQuote =
      (userState === 'inTrial' || userState === 'legacyInTrial') &&
      !shop?.config?.testQuoteSent &&
      product?.type === 'custom';
    if (shouldSendTestQuote) {
      sendTestQuote(product);
    }

    dispatch(
      openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          celebrate: true,
          content: (
            <>
              {findContextString(true)} <strong>{product?.title}</strong> was {isEditMode ? 'updated' : 'created'}
            </>
          ),
        },
      }),
    );
    if (fromChecklist) {
      history.push('/');
    } else {
      history.push(`/${context}`);
    }
    setIsSubmitting(false);
  };

  const handleFirstProductCreated = async product => {
    trackEvent('First Product Created', {
      product: removeEmpty(product),
    });

    if (!shop.checklistCompletions?.includes(ChecklistValues.ProductAdded)) {
      await dispatch(updateChecklistAction({ shop, items: [ChecklistValues.ProductAdded] }));
    }
  };

  const sendTestQuote = async product => {
    let createCustomerResponse = await dispatch(
      createCustomerAction({
        shopId: shop?.id,
        firstName: shop?.owner?.firstName,
        lastName: shop?.owner?.lastName,
        email: shop?.email,
        subscribed: true,
        subscriberOrigination: 'test-quote',
      }),
    );
    const { success } = await createTestQuote(product, shop, createCustomerResponse?.payload as Customer);
    if (success) {
      trackEvent('Automated Quote Request Sent', { context: 'admin' });
      await dispatch(getShopAction(shop.id));
    }
  };

  const handleUpdateProduct = async (values, price) => {
    const productToEdit = {
      ...values,
      shopId: shop.id,
      id: product.id,
      price,
    };

    await dispatch(
      updateProductAction({
        product: productToEdit,
        deleteSchedule: !hasSchedule(productToEdit) && hasSchedule(initalState),
      }),
    );
    trackEvent('Product Edited', { product: productToEdit });

    trackEvent(productToEdit.type === 'standard' ? 'Standard Product Edited' : productToEdit.type === 'custom' ? 'Custom Product Edited' : 'Event Edited', {
      product: productToEdit,
    });

    return productToEdit;
  };

  /*
   * I *THINK* we can probably get rid of all of the create logic on this page
   * after we migrate templates to create then redirect, as we do with the create from scratch and duplicate flows.
   * Just a note for future us that can really simplify.
   */
  const handleCreateProduct = async (values, price) => {
    const newProduct = {
      ...values,
      shopId: shop.id,
      price,
      productTemplateId,
    };

    const isFirstProduct = nonOnboardingProducts.length === 0;
    const createProductResponse = productTemplateId
      ? await dispatch(createTemplateProductAction(newProduct))
      : await dispatch(createProductAction(newProduct));
    const createdProduct = createProductResponse.payload as BaseProduct;

    //keeping this in for now to keep accuracy of older charts, per Matt R.
    trackEvent('Product Created', {
      product: removeEmpty({
        ...createdProduct,
        productTemplateTitle,
        method: 'admin',
      }),
      addMethod: productTemplateId ? 'template' : '',
    });

    //tied to userflow
    trackEvent(createdProduct.type === 'standard' ? 'Standard Product Created' : createdProduct.type === 'custom' ? 'Custom Product Created' : 'Event Created', {
      product: removeEmpty({
        ...createdProduct,
        productTemplateTitle,
        method: 'admin',
      }),
      addMethod: productTemplateId ? 'template' : '',
    });

    setProduct(createdProduct);

    if (isFirstProduct) {
      await handleFirstProductCreated(createdProduct);
    }
    return createdProduct;
  };

  const cleanValues = useCallback(
    values => {
      const safeStartingPrice = currency(values.startingPrice).value;
      const startingPrice = Math.round(safeStartingPrice * 100);

      const { image = '', ...newProductValues } = _.omit(values, 'inventory', 'useInventory', 'eventDetails.location.inPerson');

      if (!values.images) {
        newProductValues.images = [];
      }

      if (values.type === 'event') {
        if (values?.eventDetails?.location?.inPerson) {
          newProductValues.eventDetails.location.meetingUrl = '';
        } else {
          newProductValues.eventDetails.location.address = {};
        }
      }

      const modifiedNewValues = {
        ...newProductValues,
        unlimitedInventory: !values.useInventory,
        inventory: (values.useInventory && values.inventory) || 0,
        variations: newProductValues.variations.map(variation => ({
          ...variation,
          values: variation.values.map(val => ({ ...val, cost: Math.round((val.cost || 0) * 100) })),
        })),
        type,
        startingPrice,
      };

      return modifiedNewValues;
    },
    [type],
  );

  const setProductSeo = values => {
    const seoValues = values.seo;
    const isTitleManual = !seoValues.useProductTitle && seoValues.title !== initalState.seo.title;
    const isDescriptionMaual =
      !seoValues.useProductDescription && seoValues.description !== initalState.seo.description;

    const seoTitle = seoValues.useProductTitle ? values.title : seoValues.title;
    const seoDescription = stripHtml(
      seoValues.useProductDescription ? values.description : seoValues.description || '',
    );
    const seoMetadata = {
      title: seoTitle,
      description: seoDescription,
    };

    if (isTitleManual || isDescriptionMaual) {
      trackEvent('SEO Product Settings Updated', { seoMetadata });
    }
    return seoMetadata;
  };

  const submit = async values => {
    try {
      setIsSubmitting(true);
      if (hasSchedule(values) || values?.type === 'event') {
        trackEvent('Product Schedule Set');
        /* we are saving dates, let's check and see if a timezone is set in the config */
        if (!shop.config?.timeZone) {
          /* if not, set one */
          await shopRepository.updateProps(shop.id, {
            config: {
              ...shop.config,
              timeZone: defaultTimeZone,
            },
          });
        }
      }

      const cleanSeoValues = setProductSeo(values);
      const safePrice = currency(values.price).value;
      const price = Math.round(safePrice * 100);

      const hTags = values.holidays.map(h => specialToTag(h));
      const oTags = values.occasions.map(o => specialToTag(o));
      const cleanEventTags = _.uniq([...hTags, ...oTags]);

      const modifiedNewValues = _.omit(
        cleanValues({
          ...values,
          id: product.id,
          seoMetadata: { ...cleanSeoValues },
          eventTags: cleanEventTags,
        }),
        ['seo', 'images', 'holidays', 'occasions'],
      );

      !!isEditMode
        ? await handleUpdateProduct(modifiedNewValues, price)
        : await handleCreateProduct(modifiedNewValues, price);

      if (values.generationId) {
        const editDistance = levenshtein(values.rawGeneratedDesc, values.description);
        trackEvent('AI Generated Content Result', {
          generationId: values.generationId,
          editDistance,
          type: 'product-description',
          product: {
            id: product.id,
            description: values.description,
          },
        });
      }

      handleUpdateOrCreateSuccess(modifiedNewValues, isEditMode);
    } catch (err) {
      setIsSubmitting(false);
      console.error('Error Submitting Product: ', err);
    }
  };

  const variationValuesSchema = yup.object().shape({
    name: yup.string(),
    cost: yup.number(),
  });

  const variationValuesRequiredNameSchema = yup.object({
    name: yup.string().required('Please fill in this field or remove the option if no longer needed.'),
    cost: yup.number(),
  });

  const variationSchema = yup.object().shape({
    type: yup.string().required('Please choose option select type'),
    name: yup.string().required('Please enter a title'),
    values: yup.array().when('type', type => {
      const requireName = type === 'select' || type === 'multiselect';
      const valuesSchema = requireName ? variationValuesRequiredNameSchema : variationValuesSchema;
      return yup
        .array()
        .of(valuesSchema)
        .min(1, 'Must have at least one value');
    }),
    required: yup.boolean(),
  });

  let productSchema = yup.object().shape({
    title: yup.string().required('Please enter a title'),
    sellerId: yup.string().nullable(),
    description: yup.string(),
    holidays: yup.array().of(yup.string()),
    occasions: yup.array().of(yup.string()),
    allergen: yup.array().of(yup.string()),
    dietary: yup.array().of(yup.string()),
    useInventory: yup.boolean(),
    inventory: yup.number().when(['type', 'useInventory'], {
      is: (type, useInventory) => (type === 'standard' || type === 'event') && useInventory,
      then: inventory =>
        inventory
          .required()
          .min(0, 'Inventory amount must be positive')
          .integer('Inventory amount must be a whole number'),
    }),
    status: yup.string(),
    price: yup
      .number()
      .min(0.5, 'Price needs to be greater than $.50')
      .when('type', {
        is: type => type === 'standard' || type === 'event',
        then: yup
          .number()
          .min(0.5, 'Price needs to be greater than $.50')
          .required('Price is required'),
      }),
    category: yup.object(),
    images: yup.array().of(yup.object()),
    variations: yup.array().of(variationSchema),
    seo: yup.object().shape({
      title: yup.string(),
      useProductTitle: yup.boolean(),
      description: yup.string(),
      useProductDescription: yup.boolean(),
    }),
    schedule: yup
      .object({
        startTime: yup.number().nullable(),
        endTime: yup
          .number()
          .nullable()
          .moreThan(yup.ref('startTime'), 'End time must be after start time'),
      })
      .nullable(),
    isFeatured: yup.boolean(),
    generationId: yup.string().nullable(),
    rawGeneratedDesc: yup.string().nullable(),
    generatedDescription: yup.string().nullable(),
  });

  if (product?.type === 'event') {
    productSchema = productSchema.concat(
      yup.object().shape({
        eventDetails: yup.object({
          date: yup.object({
            startTime: yup.number().required(),
            endTime: yup
              .number()
              .required()
              .moreThan(yup.ref('startTime'), 'Event end time must be after event start time'),
          }),
          location: yup.object({
            inPerson: yup.boolean(),
            name: yup.string().nullable(),
            address: addressSchema(false, 'Event venue address is required').when('inPerson', {
              is: true,
              then: addressSchema(true, 'Event venue address is required').required('Event venue address is required'),
            }),
            meetingUrl: yup.string().when('inPerson', {
              is: false,
              then: yup
                .string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                  'Please enter a valid URL',
                )
                .required('URL is required'),
            }),
            notes: yup.string().nullable(),
          }),
          sendReminderEmail: yup.boolean(),
        }),
      }),
    );
  } else {
    productSchema = productSchema.concat(
      yup.object().shape({
        fulfillment: yup
          .object()
          .shape({
            pickup: yup.boolean(),
            delivery: yup.boolean(),
            shipping: yup.boolean(),
          })
          .test('at-least-one-fulfillment', 'Please select at least one fulfillment', values => {
            const { pickup, delivery, shipping } = values;
            return pickup || delivery || shipping;
          }),
      }),
    );
  }

  const goBack = (): void => {
    history.push(`/${context}`);
  };

  const findInitialTags = events => {
    const filteredEvents = events.filter(e => product.eventTags.includes(e.value));
    return filteredEvents.map(e => e.display);
  };

  const timeZone = shop?.config?.timeZone || defaultTimeZone;
  /* set default to noon today */
  const defaultStartTime = moment()
    .tz(timeZone)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .unix();
  const defaultEndTime = moment()
    .tz(timeZone)
    .set({ hour: 12, minute: 30, second: 0, millisecond: 0 })
    .unix();

  const initalState = {
    title: product?.title || '',
    sellerId: product?.sellerId || '',
    description: product?.description || '',
    holidays: !_.isEmpty(product?.eventTags) ? findInitialTags(holidaysTags) : [],
    occasions: !_.isEmpty(product?.eventTags) ? findInitialTags(occasionsTags) : [],
    allergen: product?.allergen || [],
    dietary: product?.dietary || [],
    useInventory: product?.unlimitedInventory === undefined ? true : !product?.unlimitedInventory,
    inventory: _.get(product, 'inventory'),
    status: product?.status || 'active',
    price:
      product && product.type !== 'custom' ? currency(numOrZero((product as Product).price) / 100).value : undefined,
    startingPrice:
      product && product.type === 'custom' ? currency(numOrZero((product as CustomProduct).startingPrice) / 100) : '',
    minimum: product && product.type === 'custom' ? (product as CustomProduct).minimum : '',
    policies: product && product.type === 'custom' ? (product as CustomProduct).policies : undefined,
    category: product?.category || '',
    fulfillment: (product && product.type !== 'event') ? product?.fulfillment || emptyFulfillment : undefined,
    images: product?.images || [],
    variations: product?.variations
      ? product.variations.map((variation, index) => ({
          ...variation,
          values: variation.values.map(val => ({
            ...val,
            cost: val.cost ? currency(val.cost / 100).value : undefined,
          })),
          position: variation.position || index,
        }))
      : [],
    type: product?.type || type,
    seo: {
      title: (product?.seoMetadata?.title || (!product?.seoMetadata && product?.title) || '').slice(0, 80),
      useProductTitle: product?.seoMetadata?.title === (product?.title || '').slice(0, 80) || !product?.seoMetadata,
      description: (
        product?.seoMetadata?.description ||
        (!product?.seoMetadata && stripHtml(product?.description)) ||
        ''
      ).slice(0, 300),
      useProductDescription:
        product?.seoMetadata?.description === stripHtml(product?.description).slice(0, 300) || !product?.seoMetadata,
    },
    schedule: {
      startTime: product?.schedule?.startTime,
      endTime: product?.schedule?.endTime,
    },
    isFeatured: !!product?.isFeatured,
    generationId,
    generatedDescription,
    rawGeneratedDesc: '',
    // Event Specific
    eventDetails: product && product.type === 'event' ? {
      date: {
        startTime: (product as TicketedEvent)?.eventDetails?.date?.startTime || defaultStartTime,
        endTime: (product as TicketedEvent)?.eventDetails?.date?.endTime || defaultEndTime,
      },
      location: {
        inPerson: !_.isEmpty((product as TicketedEvent)?.eventDetails?.location?.address),
        address: (product as TicketedEvent)?.eventDetails?.location?.address,
        name: (product as TicketedEvent)?.eventDetails?.location?.name,
        notes: (product as TicketedEvent)?.eventDetails?.location?.notes,
        meetingUrl: (product as TicketedEvent)?.eventDetails?.location?.meetingUrl,
      },
      sendReminderEmail: product && product.type === 'event' ? (product as TicketedEvent)?.eventDetails?.sendReminderEmail !== false : undefined,
    } : undefined,
  };

  const previewProduct = useCallback(async () => {
    const { values } = formikRef.current;
    if (values) {
      const productToPreview = cleanValues(values);

      dispatch(
        openModal({
          modalType: productToPreview.type === 'custom' ? 'CUSTOM_PRODUCT_MODAL' : 'PRODUCT_MODAL',
          modalProps: {
            show: true,
            product: productToPreview,
            customProduct: productToPreview.type === 'custom' ? productToPreview : undefined,
            initialAmount: 1,
          },
        }),
      );

      trackEvent('Product Preview Modal Opened', {
        product_values: values,
        shop: shop,
      });
    }
  }, [product, cleanValues]);

  useEffect(() => {
    const productActionsDropdown = (
      <ProductActionsDropdown
        product={product}
        isEditMode={isEditMode}
        previewProduct={previewProduct}
        context={context}
      />
    );
    if (isMobile) {
      setHeaderCTAs([
        productActionsDropdown,
        <ButtonBase onClick={previewProduct}>
          <VisibilityOutlined />
        </ButtonBase>,
      ]);
    } else {
      setHeaderCTAs([
        <Button variant="outlined" onClick={previewProduct} startIcon={<VisibilityOutlined />}>
          Preview
        </Button>,
        productActionsDropdown,
      ]);
    }
    /* need to listen to product as well, so that we get the updated `previewProduct` */
  }, [isMobile, product, previewProduct]);

  const handleErrorScroll = (id: string) => {
    const element = document.getElementById(id);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const getProductErrorBox = (errors, submitCount) => {
    return (
      !_.isEmpty(errors) &&
      submitCount > 0 && (
        <ProductErrorBox
          className={classes.errorBox}
          errors={errors}
          text="Please fill out all required information:"
          productType={type}
          scrollToError={(id: string) => {
            setOverrideTabValue(type === 'custom' ? 'Questions' : 'Variations');
            handleErrorScroll(id);
          }}
        />
      )
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          Add {findContextString(true)} | {findContextString(true) + 's'} | Castiron
        </title>
      </Helmet>
      {(isEditMode && !product && !type) || isSubmitting || isGettingProductTemplate ? (
        <Spinner size="relative" show={(isEditMode && !product) || isSubmitting || isGettingProductTemplate} />
      ) : (
        <Formik
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={submit}
          validationSchema={productSchema}
          initialValues={initalState}
          innerRef={formikRef}
        >
          {({ errors, dirty, submitCount, touched, values }): ReactElement => {
            console.debug(`Is Dirty: [${dirty}]`, {
              currentState: values,
              initalState,
            });
            return (
              <AdminForm>
                <UnsavedChangesPrompt when={(dirty || !savedNewCreate) && !isModalPopped} />
                <Field type="hidden" name="type" value={type} />
                <HeaderTabs
                  handleTabChange={() => setOverrideTabValue('')}
                  initialTabValue="General"
                  overrideTabValue={overrideTabValue}
                  tabs={[
                    {
                      value: 'General',
                      content: (
                        <Box className={classes.container}>
                          {getProductErrorBox(errors, submitCount)}
                          <EditProductForm
                            categories={shop?.categories || []}
                            onCancelClick={goBack}
                            type={type}
                            product={product}
                            setIsModalPopped={setIsModalPopped}
                          />
                        </Box>
                      ),
                    },
                    {
                      value: type === 'custom' ? 'Questions' : 'Variations',
                      content: (
                        <Grid container className={classes.container} justify="center">
                          {!!productTemplateId && getProductErrorBox(errors, submitCount)}
                          <Variations
                            type={type}
                            isEditMode={isEditMode}
                            placeholders={placeholders}
                            productId={product?.id}
                            isTemplate={!!productTemplateId}
                          />
                        </Grid>
                      ),
                    },
                    ...(features.includes('admin.seo')
                      ? [
                          {
                            value: 'SEO',
                            content: (
                              <Box className={classes.container}>
                                {getProductErrorBox(errors, submitCount)}
                                <SEO product={product} />
                              </Box>
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
              </AdminForm>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditProduct;
