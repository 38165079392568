import { Checkbox, CollapsableCard, DateInput, TimeInput, Typography } from '@castiron/components';
import { defaultTimeZone } from '@castiron/utils';
import { FormControl, Grid, Theme, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { useAppSelector } from '../../../../hooks';
import Tooltip from '../../../Tooltip';

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormControlLabel-label': {
        paddingRight: 0,
      },
    },
  },
  tooltip: {
    marginBottom: '-3px',
    marginLeft: 4,
  },
}));

const EventDate: React.FC<Props> = (props: Props) => {
  const {} = props;

  const classes = useStyles();

  const { errors, initialValues, values, setFieldValue }: any = useFormikContext();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop?.config?.timeZone || defaultTimeZone;

  const handleDayChange = async (day: moment.Moment | null) => {
    if (day && day.isValid()) {
      const newStartTime = moment.unix(values?.eventDetails?.date?.startTime);
      const newEndTime = moment.unix(values?.eventDetails?.date?.endTime);
      newStartTime.set({
        year: day.get('year'),
        month: day.get('month'),
        date: day.get('date'),
      });
      newEndTime.set({
        year: day.get('year'),
        month: day.get('month'),
        date: day.get('date'),
      });
      await setFieldValue(`eventDetails.date.startTime`, newStartTime.tz(timeZone, true).unix());
      await setFieldValue(`eventDetails.date.endTime`, newEndTime.tz(timeZone, true).unix());
    }
  };

  const handleTimeChange = async (time: moment.Moment | null, field: 'startTime' | 'endTime') => {
    if (time && time.isValid()) {
      const initialStartTime = values?.eventDetails?.date?.startTime;
      const initialEndTime = values?.eventDetails?.date?.endTime;
      const newTime = moment.unix(field === 'startTime' ? initialStartTime : initialEndTime);
      newTime.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: 0,
        millisecond: 0,
      });
      await setFieldValue(`eventDetails.date.${field}`, newTime.tz(timeZone, true).unix());
    }
  };

  return (
    <CollapsableCard defaultExpanded noScroll title="Date">
      <FormControl variant="outlined" style={{ width: '100%', margin: 0 }}>
        <Grid container item direction="column" style={{ gap: '24px' }}>
          <DateInput
            allowKeyboardInput={false}
            disablePast
            disablePastDateError={
              values?.eventDetails?.date?.startTime === initialValues?.eventDetails?.date?.startTime
            }
            label="Event Date"
            selectedDate={moment.unix(values?.eventDetails?.date?.startTime)}
            onChange={handleDayChange}
            required
            addErrorStyling={moment
              .unix(values?.eventDetails?.date?.startTime)
              .isAfter(moment.unix(values?.eventDetails?.date?.endTime), 'day')}
          />
          <TimeInput
            label="Start Time"
            name="eventDetails.date.startTime"
            required
            onChange={time => handleTimeChange(time, 'startTime')}
            timeZone={timeZone}
            addErrorStyling={!!errors?.eventDetails?.date}
          />
          <TimeInput
            label="End Time"
            name="eventDetails.date.endTime"
            required
            onChange={time => handleTimeChange(time, 'endTime')}
            timeZone={timeZone}
            addErrorStyling={!!errors?.eventDetails?.date}
          />
          {errors?.eventDetails?.date && (
            <Typography style={{ color: '#ff2b26' }} variant="caption3">{`${errors?.eventDetails?.date?.startTime ||
              ''}${errors?.eventDetails?.date?.startTime ? ', ' : ''}${errors?.eventDetails?.date?.endTime ||
              ''}`}</Typography>
          )}
          <Grid container item direction="column">
            <Grid container item direction="row">
              <Typography variant="subtitle2">⚡️ Automated Reminders</Typography>
              <Tooltip
                tooltipClass={classes.tooltip}
                title="Let us do the work for you! Leave this setting on to automatically send email reminders to your customers."
              />
            </Grid>
            <Checkbox
              checked={!!values?.eventDetails?.sendReminderEmail}
              onChange={() => setFieldValue('eventDetails.sendReminderEmail', !values?.eventDetails?.sendReminderEmail)}
              name="eventDetails.sendReminderEmail"
              checkboxClass={classes.checkbox}
              label={
                <Typography variant="body2">
                  Send an automated event reminder to your customer 24 hours before the event date.
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </FormControl>
    </CollapsableCard>
  );
};

export default EventDate;
